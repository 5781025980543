import React, { useEffect, useState } from "react";
import { Row, Col, Card, Divider, Skeleton, Table, Button } from "antd";
import BarChart from "../../Components/MyCard/Chart";
import PageHeader from "../../Components/common/pageHeader";
import CardComponent from "../../Components/MyCard/Card";
import EmployeeCard from "../../Components/MyCard/EmployeeCard";
import moment from "moment";
import axios from "axios";
import './../../Components/common/headerStyles.css';

import { DashboardOutlined } from "@ant-design/icons";
import { message } from "antd";

import {
  Attendance,
  getAttendanceWeekly,
  getEmployee,
  getSingleEmployee,
  getTodayAttendance,
  getTodayAttendanceByEmployeeId,
} from "../../API";
import ResponsiveTable from "./ResponsivTable";
import ResponsiveUserTable from "./UserTable";
const HRMSDashboard = () => {
  const token = localStorage.getItem("token");
  let isAdmin = false;
  if (token) {
      try {
          const payload = JSON.parse(atob(token.split('.')[1]));
          isAdmin = payload.isAdmin;
      } catch (error) {
          console.error("Error decoding token:", error);
      }
  }

  const [loading, setLoading] = useState(true);
  
  const [isCheckIn, setIsCheckIn] = useState(() => {
    const savedIsCheckIn = localStorage.getItem('isCheckIn');
    return savedIsCheckIn !== null ? JSON.parse(savedIsCheckIn) : true;
  });
  const [attendanceStatus, setAttendanceStatus] = useState(() => {
    const savedStatus = localStorage.getItem('attendanceStatus');
    return savedStatus ? savedStatus : 'loading';
  });

  const [name ,setName]=useState('');
  const [postion ,setPostion]=useState('');
  const [imageUrl, setImageUrl] = useState('');
  const id = localStorage?.getItem('user');

 
  useEffect(() => {
    setLoading(false);
  }, []);
  useEffect(() => {
    localStorage.setItem('isCheckIn', JSON.stringify(isCheckIn));
  }, [isCheckIn]);
  useEffect(() => {
    localStorage.setItem('attendanceStatus', attendanceStatus);
  }, [attendanceStatus]);


  const [checkInTime, setCheckInTime] = useState("");
  const [checkOutTime, setCheckOutTime] = useState("");

  const [refreshKey, setRefreshKey] = useState(0);
  const [attendanceDetails, setAttendanceDetails] = useState([]);
  const employeeId = localStorage.getItem("user")?.replace(/"/g, "");

  const [todayAttendance, setTodayAttendance] = useState([]);
  const fetchData = async () => {
    const data = await getSingleEmployee(employeeId);
    setName(data?.data?.data?.name)
    setPostion(data?.data?.data?.position) 
    setImageUrl(data?.data?.data?.image)
    }
    useEffect(() => {
  
      fetchData();
    })
    const fetchTodayAttendance = async () => {
        const response = isAdmin && await getTodayAttendance();
        const formattedData = response?.data?.todayAttendance.map((attendance) => ({
            ...attendance,
            checkIn: moment(attendance.checkInTime),
            checkOut: moment(attendance.checkOutTime),
            employeeName: attendance.employeeName,
        }));

        setTodayAttendance(formattedData);
    };

  const checkAttendanceStatus = async () => {
    try {
      const response = await getTodayAttendanceByEmployeeId(employeeId);
      if (response && response.data) {
        const todayAttendance = response.data.todayAttendance;
        if (todayAttendance) {
          if (todayAttendance.checkInTime && !todayAttendance.checkOutTime) {
            setAttendanceStatus('checkOut');
          } else if (todayAttendance.checkInTime && todayAttendance.checkOutTime) {
            setAttendanceStatus('completed');
          } else {
            setAttendanceStatus('checkIn');
          }
        } else {
          setAttendanceStatus('checkIn');
        }
      }
    } catch (error) {
      console.error("Error checking attendance status:", error);
    }
  };

  useEffect(() => {
      const fetchData = async () => {
          const daysToShow = 5;
          try {
              const response = !isAdmin && await getAttendanceWeekly(employeeId);
              if (response && response.data) {
                  const attendanceDetails = response.data.attendanceDetails;
                  let filteredAttendanceDetails = [];
                  if (attendanceDetails.length > daysToShow) {
                      const newRecords = attendanceDetails.slice(-1 * daysToShow);
                      filteredAttendanceDetails = newRecords.reverse();
                  } else {
                      filteredAttendanceDetails = attendanceDetails;
                  }
                  setAttendanceDetails(filteredAttendanceDetails);
              }
          } catch (error) {
              console.error("Error fetching data:", error);
          } finally {
              setLoading(false);
          }
      };

      fetchData();
      fetchTodayAttendance();
      checkAttendanceStatus();
  }, [employeeId, isAdmin, refreshKey]);

  useEffect(() => {
      setRefreshKey(prevKey => prevKey + 1);
  }, [isAdmin, employeeId]);

  const getAllEmployees = async () => {
      try {
          const response = await getEmployee();
          return response.data;
      } catch (error) {
          console.error(`Error fetching employees: ${error.message}`);
          return [];
      }
  };

  useEffect(() => {
      const fetchData = async () => {
          setLoading(true);
          setLoading(false);
      };
      fetchData();
      getAllEmployees();
  }, []);

  const calculateDuration = (checkInTime, checkOutTime) => {
      const startTime = moment(checkInTime);
      const endTime = moment(checkOutTime);

      if (endTime.isBefore(startTime)) {
          endTime.add(1, 'day');
      }
      const durationInMinutes = endTime.diff(startTime, "minutes");
      const hours = Math.floor(durationInMinutes / 60);
      const minutes = durationInMinutes % 60;
      return `${hours} hours ${minutes} minutes`;
  };

 // Modified handleCheckInOut function
 const handleCheckInOut = async () => {
  try {
    const response = await axios.post('https://api.metaxols.com/attendance/', {
      employeeId: employeeId,
      type: attendanceStatus === 'checkIn' ? 'checkIn' : 'checkOut'
    });

    if (response.data.success) {
      if (attendanceStatus === 'checkIn') {
        setAttendanceStatus('checkOut');
        message.success("Check-in successful");
      } else if (attendanceStatus === 'checkOut') {
        setAttendanceStatus('completed');
        message.success("Check-out successful");
      }
      setRefreshKey(prevKey => prevKey + 1);
    }
  } catch (error) {
    message.error(error.response?.data?.message || error.message);
  }
};

  const dataSource = attendanceDetails?.flatMap((record) => {
      const checkInTime = moment(record?.checkinTime);
      const checkOutTime = moment(record?.checkoutTime);
      const durationInMinutes = checkOutTime.diff(checkInTime, "minutes");
      const hours = Math.floor(durationInMinutes / 60);
      const minutes = durationInMinutes % 60;
      const duration = `${hours} hours ${minutes} minutes`;
      const key = `${record._id}_${record.day}`;
      const data = {
          key: key,
          employeeName: record.employeeName,
          position: record.position,
          checkIn: checkInTime.format("hh:mm A"),
          dayOfWeek: record.day,
      };
      if (record.checkoutTime) {
          data.checkOut = checkOutTime.format("hh:mm A");
          data.duration = duration;
      }
      return data;
  });

  const columns = [
      {
          title: "Day of Week",
          dataIndex: "dayOfWeek",
          key: "dayOfWeek",
      },
      {
          title: "Check-In",
          dataIndex: "checkIn",
          key: "checkIn",
      },
      {
          title: "Check-Out",
          dataIndex: "checkOut",
          key: "checkOut",
      },
      {
          title: "Duration",
          dataIndex: "duration",
          key: "duration",
      },
  ];
  const Todaycolumns = [
      {
          title: "Employee Name",
          dataIndex: "employeeName",
          key: "employeeName",
      },
      {
          title: "Day of Week",
          dataIndex: "dayOfWeek",
          key: "dayOfWeek",
      },
      {
          title: "Check-In",
          dataIndex: "checkIn",
          key: "checkIn",
          render: (text) => moment(text).format(" hh:mm A"),
      },
      {
          title: 'Check Out Time',
          dataIndex: 'checkOutTime',
          key: 'checkOutTime',
          render: (text) => text ? moment.utc(text).local().format('hh:mm A') : '-',
      },
      {
          title: "Duration",
          dataIndex: "duration",
          key: "duration",
          render: (text, record) => {
              const duration = calculateDuration(record.checkInTime, record.checkOutTime);
              return duration === "-1" ? "" : duration;
          },
      },
  ];

  useEffect(() => {
    const initializeAttendanceStatus = async () => {
      try {
        const response = await getAttendanceWeekly(employeeId);
        if (response && response.data) {
          const today = moment().startOf('day');
          const todayRecord = response.data.attendanceDetails.find(record => 
            moment(record.checkinTime).isSame(today, 'day')
          );

          if (todayRecord) {
            if (todayRecord.checkinTime && !todayRecord.checkoutTime) {
            console.log("the CHECKI IN TIME OF TODAY",todayRecord)
              setAttendanceStatus('checkOut');
            } else if (todayRecord.checkinTime && todayRecord.checkoutTime) {
              setAttendanceStatus('completed');
            } else {
              setAttendanceStatus('checkIn');
            }
          } else {
            setAttendanceStatus('checkIn');
          }
        }
      } catch (error) {
        console.error("Error initializing attendance status:", error);
        setAttendanceStatus('checkIn');
      }
    };

    initializeAttendanceStatus();
  }, []);

  const fetchTodayAttendanceByEmployeeId = async () => {
    try {
      const response = await getTodayAttendanceByEmployeeId(employeeId);
      if (response && response.data) {
        const todayAttendance = response.data.todayAttendance;
        if (todayAttendance) {
          if (todayAttendance.checkInTime && !todayAttendance.checkOutTime) {
            setAttendanceStatus('checkOut');
          } else if (todayAttendance.checkInTime && todayAttendance.checkOutTime) {
            setAttendanceStatus('completed');
          } else {
            setAttendanceStatus('checkIn');
          }
        } else {
          setAttendanceStatus('checkIn');
        }
      }
    } catch (error) {
      console.error("Error fetching today's attendance by employee ID:", error);
    }
  };

  useEffect(() => {
    fetchTodayAttendanceByEmployeeId();
  }, [employeeId]);

  return (
    <>
      <div className="Dashboard-page">
        <div className="page-header">
          <h1 className="page-title">
            <DashboardOutlined /> HRMS Dashboard
          </h1>
          <p className="page-subtitle">Monitor and manage your organization</p>
        </div>
  
        <div className="flex flex-wrap -mx-4 mb-4">
          <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-4">
            {isAdmin ? (
              <EmployeeCard
                title={"Welcome Admin"}
                content={"Number of Employees"}
              />
            ) : (
              <div className="bg-gradient-to-r from-indigo-500 to-purple-600 p-6 rounded-xl shadow-2xl w-full h-60 flex flex-col justify-between transform transition-transform duration-300 hover:scale-105">
      <h3 className="text-2xl font-semibold text-white">Mark Attendance</h3>
      <p className="text-white mt-2 mb-6">
        Current Date: {moment().format("MMMM Do, YYYY")}
      </p>
      <div className="flex justify-center">
        <button
          className={`bg-white font-bold py-2 px-6 rounded-full shadow transition
            ${(attendanceStatus === 'completed' || attendanceStatus === 'loading')
              ? 'text-gray-400 cursor-not-allowed' 
              : 'text-indigo-600 hover:bg-gray-100'}`}
          onClick={handleCheckInOut}
          disabled={attendanceStatus === 'completed' || attendanceStatus === 'loading'}
        >
          {attendanceStatus === 'loading' ? 'Loading...' : attendanceStatus === 'checkOut' ? 'Check-Out' : attendanceStatus === 'completed' ? 'Completed for Today' : 'Check-In'}
        </button>
      </div>
    </div>
            )}
          </div>
  
          <div className="w-full sm:w-2/3 lg:w-1/2 px-4 mb-4">
      <div className="bg-gradient-to-r from-sky-500 to-blue-600 p-8 rounded-xl shadow-2xl w-full overflow-hidden transform transition-transform duration-300 hover:scale-105">
        <div className="flex items-center space-x-6">
          {imageUrl ? (
            <img 
              src={imageUrl} 
              alt="Profile" 
              className="w-24 h-24 sm:w-44 sm:h-44 border-4 border-white mr-6 shadow-lg object-cover rounded-full sm:rounded-lg"
            />
          ) : (
            <div className="w-24 h-24 bg-gray-300 flex items-center justify-center mr-6 shadow-lg rounded-full sm:rounded-lg">
              <span className="text-4xl text-gray-600">
                {name ? name.charAt(0).toUpperCase() : ''}
              </span>
            </div>
          )}
      <div className="flex flex-col space-y-2">
        <h3 className="text-3xl font-bold text-white tracking-wide">{name || 'Welcome'}</h3>
        <p className="text-lg text-white opacity-90 font-medium">{postion || 'Position'}</p>
      </div>
    </div>
  </div>
</div>

        </div>
  
        <div className="flex flex-wrap -mx-4">
  <div className="w-full lg:w-2/3 px-4 mb-4">
      <Divider />
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          {!isAdmin ? (
    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl mx-auto">
            <ResponsiveUserTable dataSource={dataSource} columns={columns} />
    </div>

          ) : (
            <Row>
              {/* ...existing admin components... */}
            </Row>
          )}
        </>
      )}
  </div>

  {!isAdmin && (
    <div className="w-full lg:w-1/3 px-4 mb-4">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full">
        <BarChartMemo />
      </div>
    </div>
  )}
</div>
        
        {isAdmin &&<ResponsiveTable
          dataSource={todayAttendance}
          columns={Todaycolumns}
        />}
      </div>
    </>
  );
  
};

  const BarChartMemo = React.memo(BarChart);

export default HRMSDashboard;
