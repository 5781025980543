import React, { useState } from 'react';
import { Card, Row, Col, Button, Input, Typography, List } from 'antd';
import { UploadOutlined, FileOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import { message } from "antd";
import PageHeader from '../../Components/common/pageHeader';
import { uploadPolicy } from '../../API/index';
import './policies.css'; // Create this CSS file next
import './../../Components/common/headerStyles.css';

const { Title, Text } = Typography;

const Policies = () => {
  const [title, setTitle] = useState('');
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');

  const handleFileChange = (selectedFile) => {
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name); // Display the selected file name
    } else {
      setFile(null);
      setFileName('');
    }
  };

  const uploadFile = async () => {
    if (!title || !file) {
      console.error('Please enter a title and select a file');
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('file', file);
    const response=await uploadPolicy(formData)
    console.log(response)
    if(response.data.policy._id){
      message.success('Policy uploaded successfully');
      setTitle('');
      setFile(null);
      setFileName('');
    }else{
      message.error('Failed to upload policy');

    }

  };

  const handleUpload = () => {
    uploadFile();
  };

  const policyGuidelines = [
    'Policies must be in PDF, DOC, or DOCX format',
    'File size should not exceed 10MB',
    'Include version number and date in policy title',
    'Ensure content is clear and well-formatted'
  ];

  return (
    <div className="policies-page">
      <div className="page-header">
        <h1 className="page-title">
          <FileOutlined /> HRMS Policies
        </h1>
        <p className="page-subtitle">Upload and manage company policies</p>
      </div>
      <Row gutter={[24, 24]} className="policies-container">
        <Col xs={24} lg={12}>
          <Card 
            className="upload-card"
            title={<Title level={4}>Upload New Policy</Title>}
            bordered={false}
          >
            <div className="upload-section">
              <div className="upload-area" onClick={() => document.getElementById('file-input').click()}>
                {fileName ? (
                  <div className="file-preview">
                    <FileOutlined className="file-icon" />
                    <p className="file-name">{fileName}</p>
                  </div>
                ) : (
                  <div className="upload-placeholder">
                    <UploadOutlined className="upload-icon" />
                    <p>Drag and drop your policy file here</p>
                    <p className="upload-hint">Supported formats: PDF, DOC, DOCX</p>
                  </div>
                )}
                <input
                  id="file-input"
                  type="file"
                  className="hidden-input"
                  accept=".pdf, .doc, .docx"
                  onChange={(e) => handleFileChange(e.target.files[0])}
                />
              </div>
              
              <div className="form-group">
                <Input
                  placeholder="Enter policy title"
                  className="policy-input"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <Button
                  type="primary"
                  className="submit-button"
                  onClick={handleUpload}
                  disabled={!title || !file}
                >
                  Upload Policy
                </Button>
              </div>
            </div>
          </Card>
        </Col>
        
        <Col xs={24} lg={12}>
          <Card className="info-card" bordered={false}>
            <div className="info-section">
              <div className="illustration">
                <svg className="policy-illustration" viewBox="0 0 400 300" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M200 250c82.843 0 150-67.157 150-150S282.843 0 200 0 50 67.157 50 150s67.157 150 150 150z" fill="#E6F4FF"/>
                  <path d="M180 120h100v120H180z" fill="#1890FF"/>
                  <path d="M160 80h140v160H160z" fill="#40A9FF" fillOpacity=".4"/>
                  <path d="M140 100h140v160H140z" fill="#69C0FF" fillOpacity=".2"/>
                </svg>
              </div>
              
              <Title level={3} className="welcome-title">
                Welcome to HRMS Policy Management
              </Title>
              
              <Text className="info-text">
                Upload and manage your organization's policies in one centralized location.
                Ensure all documents follow the guidelines below for consistency.
              </Text>

              <div className="guidelines-section">
                <Title level={4}>
                  <SafetyCertificateOutlined className="guidelines-icon" /> 
                  Policy Guidelines
                </Title>
                <List
                  className="guidelines-list"
                  dataSource={policyGuidelines}
                  renderItem={item => (
                    <List.Item>
                      <Text>{item}</Text>
                    </List.Item>
                  )}
                />
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Policies;
