import React, { useState, useEffect } from 'react';
import { Col, Row, Input, Modal, Table, Form, Select, Pagination, DatePicker, TimePicker, Button } from 'antd';
import moment from 'moment';
import { useForm } from 'antd/lib/form/Form';
import { getAttendanceMonthly, updateAttendance, getAllAttendance, getSingleEmployee } from "../../API/index";
import './../../Components/common/headerStyles.css';
import { ClockCircleOutlined, CalendarOutlined } from '@ant-design/icons';

const { Option } = Select;

const staticMonths = [
  '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'
];

function Attendance() {
  const token = localStorage.getItem("token");
  let isAdmin = false;
  if (token) {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      isAdmin = payload.isAdmin;
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  }

  const [form] = useForm();
  const [filteredData, setFilteredData] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(moment().format('MMM'));
  const [name, setName] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [pageSize, setPageSize] = useState(20); // Added pageSize state

  const handleMonthChange = (value) => {
    const formattedMonth = moment(value, 'MM').format('MMM');
    setSelectedMonth(formattedMonth);
    setCurrentPage(1);
  };

  const getoneEmployee = async () => {
    const data = await getSingleEmployee(localStorage.getItem('user')?.replace(/"/g, ''));
    setName(data?.data?.data?.name);
  };

  const fetchData = async (search = '', page = 1) => {
    try {
      const response = isAdmin
        ? await getAllAttendance(search, page, pageSize, false, selectedMonth)
        : await getAttendanceMonthly(localStorage.getItem('user')?.replace(/"/g, ''), selectedMonth, page, pageSize);
      setTotalRecords(response?.total || 0);
      setApiData(response?.monthlyData || []);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData('', currentPage);
    getoneEmployee();
  }, [selectedMonth, isAdmin, refreshKey, currentPage]);

  const filterDataByMonth = () => {
    if (selectedMonth === null) {
      setFilteredData(apiData || []);
    } else {
      const newData = (apiData || []).filter((attendance) =>
        moment(attendance?.checkInTime).format('MMM') === selectedMonth
      );
      setFilteredData(newData);
    }
  };

  useEffect(() => {
    filterDataByMonth();
  }, [apiData, selectedMonth, refreshKey]);

  const tableColumns = [
    isAdmin ? {
      title: 'Employee Name',
      dataIndex: 'employeeId',
      key: 'employeeId',
      render: (text, record) => <span>{isAdmin ? record?.employeeId?.name : name}</span>,
    } : {},
    {
      title: 'Check in Time',
      dataIndex: 'checkInTime',
      key: 'checkInTime',
      render: (text) => moment(text).format('hh:mm A'),
    },
    {
      title: 'Check Out Time',
      dataIndex: 'checkOutTime',
      key: 'checkOutTime',
      render: (text) => text ? moment.utc(text).local().format('hh:mm A') : '-',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => (
        <span style={{ 
          color: text?.toLowerCase() === 'present' ? '#22c55e' : '#ef4444',
          fontWeight: '500'
        }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'updatedAt',
      key: 'date',
      render: (text) => new Date(text).toLocaleDateString(),
    },
    isAdmin ? {
      title: 'Actions',
      key: 'action',
      render: (text, record) => (
        <>
          <Button type="link" onClick={() => handleEdit(record)}>Edit</Button>
        </>
      ),
    } : {
      title: '',
      key: 'action',
      render: () => null,
    },
  ];

  const handleSearch = (value) => {
    setCurrentPage(1);
    fetchData(value.toLowerCase(), 1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchData('', page);
  };

  const handleEdit = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
    if (record) {
      form.setFieldsValue({
        checkinTime: moment(record?.checkInTime),
        checkoutTime: moment(record?.checkOutTime),
        date: moment(record?.date),
      });
    }
  };

  const renderMonthOption = (month) => {
    return month === 'All'
      ? moment().format('MMM')
      : moment(`${moment().year()}-${month}`, 'YYYY-MM').format('MMM');
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setSelectedRecord(null);
  };

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      await updateAttendanceInDB(values);
      setRefreshKey((prevKey) => prevKey + 1);
      setSelectedRecord(null);
      setIsModalVisible(false);
    } catch (error) {
      console.error('Error updating attendance:', error);
    }
  };

  const updateAttendanceInDB = async (updatedValues) => {
    try {
      const { checkinTime, checkoutTime, date } = updatedValues;
      const updatedRecord = {
        checkinTime: checkinTime.toISOString(),
        checkoutTime: checkoutTime.toISOString(),
        date: date.toISOString(),
        editBy: 'Admin',
      };
      const response = await updateAttendance(selectedRecord._id, updatedRecord);
      if (response.data.success) {
        setApiData((prevData) =>
          prevData.map((item) =>
            item._id === selectedRecord._id ? { ...item, ...updatedRecord } : item
          )
        );
      } else {
        console.error('Failed to update attendance:', response.data.message);
      }
    } catch (error) {
      console.error('Error updating attendance:', error);
    }
  };

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <div className="bg-gradient-to-r from-blue-500 to-blue-700 p-6 rounded-lg shadow-lg mb-6">
        <div className="page-header mb-6">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="text-white mb-4 md:mb-0">
              <h1 className="page-title text-2xl font-bold flex items-center gap-2">
                <ClockCircleOutlined /> Attendance Management
              </h1>
              <p className="page-subtitle opacity-90">employee attendance records</p>
            </div>
            <div className="flex flex-col sm:flex-row items-center gap-4 w-full md:w-auto">
                <span className="text-white flex items-center gap-2">
                  <CalendarOutlined className="text-white" />
                  Month:
                </span>
                <Select
                  defaultValue={moment().format('MM')}
                  className="min-w-[120px] border-none focus:shadow-none"
                  onChange={handleMonthChange}
                  size="large"
                  suffixIcon={<CalendarOutlined className="text-gray-400" />}
                  dropdownStyle={{ borderRadius: '8px' }}
                >
                  {staticMonths.map(month => (
                    <Select.Option key={month} value={month}>
                      {renderMonthOption(month)}
                    </Select.Option>
                  ))}
                </Select>
              {isAdmin && (
                <Input.Search
                  placeholder="Search by employee name..."
                  onSearch={handleSearch}
                  onChange={(e) => handleSearch(e.target.value)}
                  className="w-full sm:w-64 md:w-80"
                  size="large"
                  style={{ borderRadius: '8px' }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      
      <div className="w-full">
        <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
          <div className="flex flex-col lg:flex-row justify-between items-center mb-6 space-y-4 lg:space-y-0">
           
          </div>

          <div className="overflow-x-auto w-full"> {/* Added w-full here */}
            <Table
              dataSource={filteredData}
              columns={tableColumns}
              pagination={false}
              bordered={false}
              rowClassName="hover:bg-gray-50 transition-all duration-200"
              className="w-full"
              components={{
                header: {
                  cell: props => (
                    <th className="bg-gray-50 text-gray-700 font-semibold px-4 py-3">
                      {props.children}
                    </th>
                  )
                },
                body: {
                  cell: props => (
                    <td className="px-4 py-4 border-b border-gray-100">
                      {props.children}
                    </td>
                  )
                }
              }}
            />
          </div>

          <div className="mt-6 flex justify-center">
            <Pagination
              current={currentPage}
              total={totalRecords}
              pageSize={20}
              onChange={handlePageChange}
              showLessItems={false}
              showSizeChanger={false}
              style={{ marginTop: '20px', textAlign: 'center' }}
            />
          </div>
        </div>

        <Modal
          title={<span className="text-xl font-semibold text-gray-800">Edit Attendance Record</span>}
          visible={isModalVisible}
          onCancel={handleModalCancel}
          onOk={handleSave}
          width={500}
          centered
          className="rounded-lg overflow-hidden"
          footer={[
            <Button 
              key="cancel" 
              onClick={handleModalCancel}
              className="hover:bg-gray-100 border border-gray-200"
            >
              Cancel
            </Button>,
            <Button 
              key="save" 
              type="primary" 
              onClick={handleSave}
              className="bg-blue-500 hover:bg-blue-600"
            >
              Save Changes
            </Button>,
          ]}
        >
          <Form
            form={form}
            layout="vertical"
            className="mt-4 space-y-4"
          >
            <Form.Item 
              label={<span className="text-gray-700 font-medium">Check-in Time</span>} 
              name="checkinTime"
              className="mb-4"
            >
              <TimePicker 
                format="hh:mm A"
                className="w-full rounded-lg" 
                size="large"
                use12Hours
                showNow
                minuteStep={1}
                showSecond={false}
                allowClear
                placeholder="Select check-in time"
                onChange={(time) => {
                  if (time) {
                    const formattedTime = moment(time).format('YYYY-MM-DD HH:mm:ss');
                    form.setFieldsValue({
                      checkinTime: moment(formattedTime)
                    });
                  }
                }}
                onSelect={(time) => {
                  if (time) {
                    const formattedTime = moment(time).format('YYYY-MM-DD HH:mm:ss');
                    form.setFieldsValue({
                      checkinTime: moment(formattedTime)
                    });
                  }
                }}
                defaultOpenValue={moment('00:00', 'HH:mm')}
              />
            </Form.Item>
            <Form.Item 
              label={<span className="text-gray-700 font-medium">Check-out Time</span>} 
              name="checkoutTime"
              className="mb-4"
            >
              <TimePicker 
                format="hh:mm A"
                className="w-full rounded-lg" 
                size="large"
                use12Hours
                showNow
                minuteStep={1}
                showSecond={false}
                allowClear
                placeholder="Select check-out time"
                onChange={(time) => {
                  if (time) {
                    const formattedTime = moment(time).format('YYYY-MM-DD HH:mm:ss');
                    form.setFieldsValue({
                      checkoutTime: moment(formattedTime)
                    });
                  }
                }}
                onSelect={(time) => {
                  if (time) {
                    const formattedTime = moment(time).format('YYYY-MM-DD HH:mm:ss');
                    form.setFieldsValue({
                      checkoutTime: moment(formattedTime)
                    });
                  }
                }}
                defaultOpenValue={moment('00:00', 'HH:mm')}
              />
            </Form.Item>
            <Form.Item 
              label={<span className="text-gray-700 font-medium">Date</span>} 
              name="date"
              className="mb-4"
            >
              <DatePicker format="YYYY-MM-DD" className="w-full rounded-lg" size="large" />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
}

export default Attendance;