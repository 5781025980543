import React, { useEffect, useState } from "react";
import { Row, Col, Button, Modal,Pagination, Form,Tag, Input, Select, DatePicker } from "antd";
import GenericTable from "../../Components/Tables";
import PageHeader from "../../Components/common/pageHeader";
import moment from "moment"; // Import moment for date formatting
import axios from "axios";
import {message} from 'antd'
import { CalendarOutlined } from "@ant-design/icons";

import { getEmployeeLeave,getSingleEmployee,postLeaveRequest ,getAllEmployeeLeaves,AcceptLeave, RejectLeave} from "../../API";
const { Option } = Select;
function Leaves() {
  const token = localStorage.getItem("token");
  let isAdmin = false;
  if (token) {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      isAdmin = payload.isAdmin;
      console.log(isAdmin);
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  }
  const [form] = Form.useForm();
  const[name,setName]=useState('');
const [endDays, setEndDays] = useState('');

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [refreshKey,setRefreshKey]=useState(0);
  const [selectedLeaveReason, setSelectedLeaveReason] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [leaves, setLeaves] = useState([]); // Add state for leaves
  const [getAllLeaves, setAllLeaves] = useState([]); // Add state for leaves
  const [reasons, setReasons] = useState([]); // Add state for leaves
  const employee_id = localStorage.getItem("user")?.replace(/"/g, "");











  const fetchAllLeaveData = async (search = '', page = 1, pageSize = 10) => {
    try {
      const response = await getAllEmployeeLeaves(search, page, pageSize, false);
      if (response.data?.success) {
        const leaveRequests = response.data?.leaveRequests;
          const leaveReasons = leaveRequests.map((leave) => leave.reason);
          setReasons(leaveReasons);
        setAllLeaves(leaveRequests);
        setTotalRecords(response.data.total);
      } else {
        console.error('Failed to fetch leave data.');
      }
    } catch (error) {
      console.error('Error fetching leave data:', error);
      setLeaves([]);
      setReasons([]);
    }
  };
  const fetchData = async () => {
    try {
      const response = !isAdmin && await getEmployeeLeave(employee_id);
      if (response?.data?.success) {
        const leaveRequests = response?.data?.leaveRequests;
        setLeaves(leaveRequests);
      } else {
        console.error('Failed to fetch leave data.');
      
      }
    } catch (error) {
      console.error('Error fetching leave data:', error)
    }
  };
  useEffect(() => {
    fetchData();
    getEmployee();
  }, [employee_id,reasons,refreshKey,endDays]);

  useEffect(() => {
fetchAllLeaveData('', currentPage);
  }, [employee_id,refreshKey]);


  const handleEndDateChange = (date, dateString) => {
    const startDate = form.getFieldValue('startDate');
    if (startDate && date) {
      const daysDiff = moment(date).diff(startDate, 'days'); // Calculate difference in days
      const formattedEndDate = moment(date).format('MMMM Do YYYY, h:mm:ss a'); // Format end date with AM/PM
      setEndDays(`${daysDiff} days - ${formattedEndDate}`); // Update state with difference in days and formatted end date
    }
  };
  

  const handleSearch = (value) => {
    setCurrentPage(1);
    fetchAllLeaveData(value.toLowerCase());
  };
  
  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchAllLeaveData('', page);
  };



  const handleShowReason = (reason) => {
    setSelectedLeaveReason(reason);
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const getEmployee=async ()=>{
    const data = await getSingleEmployee(employee_id);
    setName(data?.data?.data?.name)
  }
  

  const handleAddLeave = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleLeaveAccept = async (record) => {
    const leaveId = record?.employeeId;
    try {
      const response = await AcceptLeave(employee_id, leaveId);
      if (response?.status === 200) {
        setAllLeaves((prevLeaves) =>
          prevLeaves.map((leave) =>
            leave.employeeId === record.employeeId
              ? { ...leave, status: 'Approved' }
              : leave
          )
        );
        setLeaves((prevLeaves) =>
          prevLeaves.map((leave) =>
            leave.employeeId === record.employeeId
              ? { ...leave, status: 'Approved' }
              : leave
          )
        );
        message.success(`Leave Request Approved successfully!`);
        setRefreshKey((prevKey) => prevKey + 1);
      } else {
        message.error(`Failed to approve leave request.`);
      }
    } catch (error) {
      console.error('Error approving leave request:', error);
      message.error(`Something went wrong`);
    }
  };
  
  
  
  const handleLeaveReject = async (record) => {
    const leaveId = record?.employeeId; 
    try {
      const response = await RejectLeave(employee_id, leaveId);
      if (response?.status === 200) {
        setAllLeaves((prevLeaves) =>
          prevLeaves.map((leave) =>
            leave.employeeId === record.employeeId
              ? { ...leave, status: 'Rejected' }
              : leave
          )
        );
        setLeaves((prevLeaves) =>
          prevLeaves.map((leave) =>
            leave.employeeId === record.employeeId
              ? { ...leave, status: 'Rejected' }
              : leave
          )
        );
        message.error(`Leave Rejected Due to Some Reasons!`);
        setRefreshKey((prevKey) => prevKey + 1);
      } else {
        message.error(`Failed to reject leave request.`);
      }
    } catch (error) {
      console.error('Error rejecting leave request:', error);
      message.error(`Something went wrong`);
    }
  };
  

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        postLeaveRequest(values)
          .then((response) => {
            setLeaves((prevLeaves) => [
              ...prevLeaves,
              {
                leaveType: values?.leaveType,
                startDate: moment(values?.startDate).format("YYYY-MM-DD"),
                endDate: moment(values?.endDate).format("YYYY-MM-DD"),
                status: "Pending",
              },
            ]);
            form.resetFields();
            setIsModalVisible(false);
    message.success('Leave request added successfully');
    setRefreshKey((prevKey) => prevKey + 1);

          })
          
          .catch((error) => {
            if(error.response && error.response.status === 400){
              const errorMessage = error.response.data.message || "An error occurred";
              message.error(errorMessage);

            }
            console.error('Error submitting leave request:', error);
          });
      })
      .catch((info) => {
        console.error("Validate Failed:", info);
      });
  };

  const tableColumns = [
   isAdmin ? {
      title: 'Employee Name',
      dataIndex: 'employeeId',
      key: 'employeeId',
      render: (text, record) => {
        if (isAdmin) {
          return <span>{record?.employeeName}</span>;
        } else {
          return <span>{name}</span>;
        }
      },
    }:{},
    
    
    {
      title: 'Leave Type',
      dataIndex: 'leaveType',
      key: 'leaveType',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (text) => new Date(text).toLocaleDateString(),
    }
,    
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        let color = '';
        switch (text) {
          case 'Pending':
            color = 'orange';
            break;
          case 'Approved':
            color = 'lightgreen';
            break;
          case 'Rejected':
            color = 'lightcoral';
            break;
          default:
            break;
        }
        return <Tag style={{color: 'black'}} color={color}>{text}</Tag>;
      },
    },
    {
        title: 'Reasons',
        key: 'action',
        render: (text, record) => {
          const words = record?.reasons?.split(' ');
          const truncatedReason = words?.slice(0, 10).join(' ');
          return (
            <>
              <Button type="link" onClick={() => handleShowReason(record.reasons)}>
                {truncatedReason} {words?.length > 10 ? '...' : ''}
              </Button>
            </>
          );
        }
        
      },
      // {
      //   title: ' Days',
      //   dataIndex: 'endDays',
      //   key: 'endDays',
       
      // },
      isAdmin ? {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <>
            <Button
              type="primary"
              style={{ backgroundColor: 'green', marginLeft: '8px' }}
              onClick={() => handleLeaveAccept(record)}
              disabled={record.status !== 'Pending'}
            >
              Accept
            </Button>
            <Button
              type="primary"
              style={{ backgroundColor: 'red', marginLeft: '8px' }}
              onClick={() => handleLeaveReject(record)}
              disabled={record.status !== 'Pending'}
            >
              Reject
            </Button>
          </>
        ),
      } : {
        title: '',
        key: 'action',
        render: () => null,
      },
  ];

  
  const tableDataForAll = getAllLeaves.map((leave, index) => ({
    key: index.toString(),
    employeeId: leave._id,
    employeeName: leave?.employeeId?.name,
    leaveType: leave.leaveType,
    startDate: leave.startDate,
    endDate: leave.endDate,
    status: leave.status,
    reasons: leave.reason,
    endDays: leave.endDays
    
  }));

  const tableData = leaves.map((leave, index) => ({
    key: index.toString(),
    employeeId: leave._id,
    employeeName: leave?.employeeName,
    leaveType: leave.leaveType,
    startDate: leave.startDate,
    endDate: leave.endDate,
    status: leave.status,
    reasons: leave.reason,
    endDays: leave.endDays
  }));
  const handleRowClick = (record) => {
    if (record && record.id) {
    } else {
    }
  };
  const tableHeader = 'Employee Leaves';

  return (
    <>
      <div className="leaves-page bg-gradient-to-r from-blue-500 to-blue-700 p-6 rounded-lg shadow-lg">
        <div className="page-header mb-6">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="text-white mb-4 md:mb-0">
              <h1 className="page-title text-2xl font-bold flex items-center gap-2">
                <CalendarOutlined /> Leave Management
              </h1>
              <p className="page-subtitle opacity-90">employee leaves</p>
            </div>
            <div className="flex flex-col sm:flex-row items-center gap-4 w-full md:w-auto">
              {isAdmin && (
                <Input.Search
                  placeholder="Search by employee name..."
                  onSearch={handleSearch}
                  onChange={(e) => handleSearch(e.target.value)}
                  className="w-full sm:w-64 md:w-80"
                  size="large"
                  style={{ borderRadius: '8px' }}
                />
              )}
              {!isAdmin && (
                <Button
                  className="w-full sm:w-auto bg-white text-blue-700 hover:bg-blue-50 
                            border-2 border-white hover:border-blue-50 shadow-md 
                            flex items-center justify-center gap-2"
                  onClick={handleAddLeave}
                  size="large"
                >
                  <CalendarOutlined />
                  Apply for Leave
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="Matrix-page p-6 bg-gray-100 min-h-screen">
     
      <div className="w-full">
        <div className="table-container bg-white p-6 rounded-lg shadow-md">
          <PageHeader title={'Leave History'} />
          {isAdmin ? (
            <GenericTable
              header={tableHeader}
              onRowClick={handleRowClick}
              columns={tableColumns}
              data={tableDataForAll}
         className="leaves-table"
            />
          ) : (
            <GenericTable
              header={tableHeader}
              onRowClick={handleRowClick}
              columns={tableColumns}
              data={tableData}
              className="leaves-table"
            />
          )}
        </div>
      </div>

      <Modal
        title="Leave Reason"
        visible={modalVisible}
        onCancel={handleModalCancel}
        footer={[
          <Button
            key="cancel"
            onClick={handleModalCancel}
            className="bg-gray-500 hover:bg-gray-600 text-white font-semibold px-4 py-2 rounded-md shadow transition duration-300"
          >
            Close
          </Button>,
        ]}
        className="bg-white rounded-lg shadow-lg"
      >
        <p className="text-gray-700">{selectedLeaveReason}</p>
      </Modal>

      <Modal
        title="Apply for Leave"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Submit"
        cancelText="Cancel"
       
      >
        <Form form={form} layout="vertical" initialValues={{ employeeId: employee_id }}>
          <Form.Item
            name="startDate"
            label="Start Date"
            rules={[{ required: true, message: 'Start date is required' }]}
          >
            <DatePicker className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500" />
          </Form.Item>

          <Form.Item
            name="endDate"
            label="End Date"
            rules={[{ required: true, message: 'End date is required' }]}
          >
            <DatePicker className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500" onChange={handleEndDateChange} />
          </Form.Item>

          <Form.Item
            name="reason"
            label="Reason of Leave"
            rules={[
              { required: true, message: 'Enter the Reason of Leave' },
              { max: 300, message: 'Maximum length reached (300 characters)' },
            ]}
          >
            <Input.TextArea
              autoSize={{ minRows: 3, maxRows: 6 }}
              placeholder="Enter the reason for leave (max 300 characters)"
              className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </Form.Item>

          <Form.Item
            name="leaveType"
            label="Leave Type"
            rules={[{ required: true, message: 'Leave type is required' }]}
          >
            <Select placeholder="Select leave type" className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500">
              <Option value="casual">Casual</Option>
              <Option value="annual">Annual</Option>
              <Option value="medical">Medical</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      <div className="mt-6 flex justify-center">
        <Pagination
          current={currentPage}
          total={totalRecords}
          pageSize={20}
          onChange={handlePageChange}
          className="mt-4"
        />
      </div>
    </div>
  </>
);
  
}

export default Leaves;