import { Form, Input, Button, Row, Col,message, DatePicker, Select } from 'antd';
import { useNavigate ,useParams} from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { getEmployeeDetails,updateEmployeeDetails } from '../../API'

import { useEffect } from 'react';
const { Option } = Select;

const EmployeeDetail = () => {
  const [form] = Form.useForm();
  const { id } = useParams(); 
  const history = useNavigate();
const onFinish = async (values) => {
  try {
    const data =await  updateEmployeeDetails(id, values);
    if(data.status===200){
      message.success('Employee data updated successfully!');
      history('/employee');
    }
  } catch (error) {
    console.error('Error updating employee data:', error);
  }
};
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getEmployeeDetails(id); 
        const employeeData = response.data.employee;
      const initialValues = {
        fullName: employeeData?.name,
        birthDate: moment(employeeData?.dateOfBirth),
        streetAddressLine2: employeeData?.currentAddress.streetAddress,
        city: employeeData?.currentAddress?.city,
        state: employeeData?.currentAddress?.state,
        postalCode: employeeData?.currentAddress?.postalCode,
        email: employeeData?.email,
        phoneNumber: employeeData?.phone,
        linkedIn: employeeData?.linkedIn,
        positionApplied: employeeData?.position,
      };
      form.setFieldsValue(initialValues);
        form.setFields([
          { name: 'name', value: initialValues.fullName },
          { name: 'dateOfBirth', value: initialValues.birthDate },
          { name: ['currentAddress', 'streetAddress'], value: initialValues.streetAddressLine2 },
          { name:  ['currentAddress', 'city'], value: initialValues.city },
          { name:  ['currentAddress', 'state'], value: initialValues.state },
          { name:  ['currentAddress', 'postalCode'], value: initialValues.postalCode },
          { name: 'email', value: initialValues.email },
          { name: 'phone', value: initialValues.phoneNumber },
          { name: 'linkedIn', value: initialValues.linkedIn },
          { name: 'position', value: initialValues.positionApplied },
        ]);
  
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };

    fetchData();
  }, [form, id]); 

  return (
    <div className="max-w-xl mx-auto p-6 bg-blue-50 rounded-lg shadow-md">
      <h1 className="text-3xl font-semibold mb-6 text-blue-700">Employee Details</h1>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          label="Full Name"
          name="name"
          rules={[{ required: true, message: 'Please enter the full name!' }]}
          className="mb-4"
        >
          <Input className="border-blue-300 focus:border-blue-500 focus:ring-blue-500" />
        </Form.Item>
        <Row gutter={16} className="mb-4">
          <Col span={12}>
            <Form.Item label="Birth Date" name="dateOfBirth">
              <DatePicker className="w-full border-blue-300 focus:border-blue-500 focus:ring-blue-500" />
            </Form.Item>
          </Col>
        </Row>
        {/* Current Address Section */}
        <Form.Item
          label="Current Address"
          name={['currentAddress', 'streetAddress']}
          rules={[{ required: true, message: 'Please enter the street address!' }]}
          className="mb-4"
        >
          <Input placeholder="Street Address" className="border-blue-300 focus:border-blue-500 focus:ring-blue-500" />
        </Form.Item>
        <Row gutter={16} className="mb-4">
          <Col span={12}>
            <Form.Item name={['currentAddress', 'city']} rules={[{ required: true, message: 'Please enter the city!' }]}>
              <Input placeholder="City" className="border-blue-300 focus:border-blue-500 focus:ring-blue-500" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={['currentAddress', 'state']} rules={[{ required: true, message: 'Please enter the state/province!' }]}>
              <Input placeholder="State / Province" className="border-blue-300 focus:border-blue-500 focus:ring-blue-500" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name={['currentAddress', 'postalCode']} rules={[{ required: true, message: 'Please enter the postal/zip code!' }]}>
              <Input placeholder="Postal / Zip Code" className="border-blue-300 focus:border-blue-500 focus:ring-blue-500" />
            </Form.Item>
          </Col>
        </Row>
        {/* Email, Phone, LinkedIn Section */}
        <Form.Item
          label="Email Address"
          name="email"
          rules={[{ type: 'email', message: 'Please enter a valid email address!' }]}
          className="mb-4"
        >
          <Input placeholder="example@example.com" className="border-blue-300 focus:border-blue-500 focus:ring-blue-500" />
        </Form.Item>
        <Form.Item label="Phone Number" name="phone" className="mb-4">
          <Input placeholder="(000) 000-0000" className="border-blue-300 focus:border-blue-500 focus:ring-blue-500" />
        </Form.Item>
        <Form.Item label="LinkedIn" name="linkedIn" className="mb-4">
          <Input placeholder="LinkedIn Profile URL" className="border-blue-300 focus:border-blue-500 focus:ring-blue-500" />
        </Form.Item>
        {/* Position Applied Section */}
        <Form.Item label="Position Applied" name="position" className="mb-6">
          <Select placeholder="Please Select" className="border-blue-300 focus:border-blue-500 focus:ring-blue-500">
            <Option value="position1">Position 1</Option>
            <Option value="position2">Position 2</Option>
          </Select>
        </Form.Item>
        {/* Save Button */}
        <Form.Item>
          <div className="flex justify-end">
            <Button type="primary" htmlType="submit" className="w-32 h-12 bg-blue-600 hover:bg-blue-700 shadow-md">
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
  
};

export default EmployeeDetail;
