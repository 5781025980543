import React, { useState, useEffect } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { Drawer, Button } from 'antd';
import { useLocation } from 'react-router-dom';
import 'tailwindcss/tailwind.css';

const MobileMenu = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const location = useLocation();

  const showDrawer = () => {
    setVisible(true);
  };

  const closeDrawer = () => {
    setVisible(false);
  };

  const handleMenuClick = () => {
    setVisible(false);
  };

  useEffect(() => {
    setVisible(false);
  }, [location.pathname]);

  return (
    <div className="">
      <Button
        type="primary"
        icon={<MenuOutlined className="z-10 relative" height={40} width={20} />}
        onClick={showDrawer}
        className="z-[999] mt-0 ml-0 fixed top-9 left-1 p-2 border border-gray-300 bg-blue-400 rounded shadow hover:bg-gray-100"
      />
      <Drawer
        title="Menu"
        placement="left"
        onClose={closeDrawer}
        visible={visible}
        className="z-20"
      >
        <div onClick={handleMenuClick}>
          {children}
        </div>
      </Drawer>
    </div>
  );
};

export default MobileMenu;
