import React from 'react';
import moment from 'moment'; // Ensure you have moment installed if you use it
import './../../Components/common/headerStyles.css';

const ResponsiveTable = ({ dataSource, columns }) => {
  const calculateDuration = (checkInTime, checkOutTime) => {
    if (!checkInTime || !checkOutTime) {
      return '-';
    }
    const startTime = moment(checkInTime);
    const endTime = moment(checkOutTime);

    if (endTime.isBefore(startTime)) {
      endTime.add(1, 'day');
    }
    const durationInMinutes = endTime.diff(startTime, "minutes");
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
    return `${hours}h ${minutes}m`;
  };

  return (
    <div className="overflow-x-auto rounded-lg shadow-lg">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-100">
          <tr>
            {columns.map((column) => (
              <th
                key={column.key}
                className="px-4 py-4 text-left text-xs md:text-sm font-semibold text-black uppercase tracking-wider"
              >
                {column.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {dataSource.map((data) => {
            // Log checkIn and checkOut times
            console.log("CheckIn Time:", data.checkIn);
            console.log("CheckOut Time:", data.checkOut);
            return (
              <tr key={data.key} className="hover:bg-gray-50 transition-colors duration-200">
                {columns.map((column) => (
                  <td
                    key={column.key}
                    className="px-4 py-3 text-sm md:text-base text-gray-700 whitespace-nowrap"
                  >
                    {column.dataIndex === 'checkIn' || column.dataIndex === 'checkOut' ?
                      (data[column.dataIndex] ? moment(data[column.dataIndex]).format('hh:mm A') : '-') :
                      column.dataIndex === 'duration' ?
                        calculateDuration(data.checkIn, data.checkOut) :
                        String(data[column.dataIndex] ?? '-')
                    }
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ResponsiveTable;
