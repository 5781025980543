import { useState } from 'react';
import { Form, Input, Button, Row, Col, DatePicker, Select, Upload, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import { CreateUser } from '../../API';
import { Tag } from 'antd';

const { Option } = Select;
const EmployeeDetail = () => {
  const [form] = Form.useForm();
  const history = useNavigate();
  const [copied, setCopied] = useState(false);
  const [employeeId, setId] = useState('');
  const [imageUrl, setImageUrl] = useState(null);
  const [fileList, setFileList] = useState([]);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG files!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return false; // Return false to stop automatic upload
  };

  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      
      // Append all form fields
      Object.keys(values).forEach(key => {
        if (key !== 'profilePicture') {
          if (typeof values[key] === 'object' && !Array.isArray(values[key])) {
            formData.append(key, JSON.stringify(values[key]));
          } else {
            formData.append(key, values[key]);
          }
        }
      });

      // Append image if exists
      if (fileList[0]) {
        formData.append('file', fileList[0].originFileObj);
      }

      const data = await CreateUser(formData);
      
      if (data.status === 200) {
        const newPassword = data.data.data.password;
        const employeeId = data.data.data.employeeId;
        setId(employeeId);
        showMessage(newPassword, employeeId);
        message.success('Employee created successfully');
        history('/employee');
      }
    } catch (error) {
      console.error('Error creating employee:', error);
      message.error('Failed to create employee');
    }
  };

  const showMessage = (text, employeeId) => {
    message.success(
      <div>
        <h4>Employee Details</h4>
        <span>
          Employee Password:
          <Tag color="orange">{text}</Tag>
        </span>
        <span>
          Employee Id:
          <Tag color="orange">{employeeId}</Tag>
        </span>
  
        <Button
          style={{ marginLeft: 8 }}
          onClick={() => {
            copyToClipboard(`${text} ${employeeId}`); 
          }}
        >
          Copy
        </Button>
      </div>,
      20
    );
  };
  
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    message.success('Password copied to clipboard', 2);
  };

  const handleImageUpload = (info) => {
    if (info.file.status === 'done') {
      setImageUrl(info.file.response.url);
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  return (
    <div className="max-w-xl mx-auto p-6 bg-blue-50 rounded-lg shadow-md">
      <h1 className="text-3xl font-semibold mb-6 text-blue-700">Add New Employee</h1>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          label="Profile Picture"
          name="profilePicture"
          className="mb-4"
        >
          <Upload
            listType="picture-card"
            fileList={fileList}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            maxCount={1}
          >
            {fileList.length < 1 && (
              <div>
                <UploadOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          label="Full Name"
          name="name"
          rules={[{ required: true, message: 'Please enter the full name!' }]}
          className="mb-4"
        >
          <Input className="border-blue-300 focus:border-blue-500 focus:ring-blue-500" />
        </Form.Item>
        <Form.Item
          label="Father Name"
          name="fatherName"
          rules={[{ required: true, message: 'Please enter the father\'s name!' }]}
          className="mb-4"
        >
          <Input className="border-blue-300 focus:border-blue-500 focus:ring-blue-500" />
        </Form.Item>
        <Row gutter={16} className="mb-4">
          <Col span={12}>
            <Form.Item label="Birth Date" name="dateOfBirth">
              <DatePicker className="w-full border-blue-300 focus:border-blue-500 focus:ring-blue-500" />
            </Form.Item>
          </Col>
        </Row>
        {/* Current Address Section */}
        <Form.Item
          label="Current Address"
          name={['currentAddress', 'streetAddress']}
          rules={[{ required: true, message: 'Please enter the street address!' }]}
          className="mb-4"
        >
          <Input placeholder="Street Address" className="border-blue-300 focus:border-blue-500 focus:ring-blue-500" />
        </Form.Item>
        <Row gutter={16} className="mb-4">
          <Col span={12}>
            <Form.Item name={['currentAddress', 'city']} rules={[{ required: true, message: 'Please enter the city!' }]}>
              <Input placeholder="City" className="border-blue-300 focus:border-blue-500 focus:ring-blue-500" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={['currentAddress', 'state']} rules={[{ required: true, message: 'Please enter the state/province!' }]}>
              <Input placeholder="State / Province" className="border-blue-300 focus:border-blue-500 focus:ring-blue-500" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name={['currentAddress', 'postalCode']} rules={[{ required: true, message: 'Please enter the postal/zip code!' }]}>
              <Input placeholder="Postal / Zip Code" className="border-blue-300 focus:border-blue-500 focus:ring-blue-500" />
            </Form.Item>
          </Col>
        </Row>
        {/* Email, Phone, LinkedIn Section */}
        <Form.Item
          label="Email Address"
          name="email"
          rules={[{ type: 'email', message: 'Please enter a valid email address!' }]}
          className="mb-4"
        >
          <Input placeholder="example@example.com" className="border-blue-300 focus:border-blue-500 focus:ring-blue-500" />
        </Form.Item>
        <Form.Item label="Phone Number" name="phone" className="mb-4">
          <Input placeholder="(000) 000-0000" className="border-blue-300 focus:border-blue-500 focus:ring-blue-500" />
        </Form.Item>
        <Form.Item label="LinkedIn" name="linkedIn" className="mb-4">
          <Input placeholder="LinkedIn Profile URL" className="border-blue-300 focus:border-blue-500 focus:ring-blue-500" />
        </Form.Item>
        {/* Position Applied Section */}
        <Form.Item label="Position Applied" name="position" className="mb-6">
          <Select placeholder="Please Select" className="border-blue-300 focus:border-blue-500 focus:ring-blue-500">
            <Option value="position1">Position 1</Option>
            <Option value="position2">Position 2</Option>
          </Select>
        </Form.Item>
        {/* Submit Button */}
        <Form.Item>
          <Button type="primary" htmlType="submit" className="w-full h-12 bg-blue-600 hover:bg-blue-700 shadow-md">
            Add Employee
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EmployeeDetail;
