import React from 'react';
import './index.scss';

const GenericTable = ({ columns, data }) => {
  return (
    <div className="overflow-x-auto rounded-lg shadow">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-100">
          <tr>
            {columns.map((column) => (
              <th
                key={column.key || column.dataIndex}
                className="px-4 py-4 text-left text-xs md:text-sm font-semibold text-black uppercase tracking-wider"
              >
                {column.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data.map((record) => (
            <tr key={record.key} className="hover:bg-gray-50 transition-colors duration-200">
              {columns.map((column) => (
                <td
                  key={column.key || column.dataIndex}
                  className="px-4 py-3 text-sm md:text-base text-gray-900"
                >
                  {column.render
                    ? column.render(record[column.dataIndex], record)
                    : record[column.dataIndex] || '-'}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GenericTable;
