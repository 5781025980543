import React from 'react';
import moment from 'moment';
import './../../Components/common/headerStyles.css';

const ResponsiveUserTable = ({ dataSource, handleCheckInOut }) => {
  return (
    <div className="overflow-x-auto rounded-lg shadow">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-4 py-4 text-left text-xs md:text-sm font-semibold text-black uppercase tracking-wider">Day of Week</th>
            <th className="px-4 py-4 text-left text-xs md:text-sm font-semibold text-black uppercase tracking-wider">Check-In</th>
            <th className="px-4 py-4 text-left text-xs md:text-sm font-semibold text-black uppercase tracking-wider">Check-Out</th>
            <th className="px-4 py-4 text-left text-xs md:text-sm font-semibold text-black uppercase tracking-wider">Duration</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {dataSource.map((record) => (
            <tr key={record.key} className="hover:bg-gray-50 transition-colors duration-200">
              <td className="px-4 py-3 text-sm md:text-base text-gray-900 font-medium">{record.dayOfWeek}</td>
              <td className="px-4 py-3 text-sm md:text-base text-gray-600">{record.checkIn}</td>
              <td className="px-4 py-3 text-sm md:text-base text-gray-600">
                {record.checkOut || '-'}
              </td>
              <td className="px-4 py-3 text-sm md:text-base text-gray-600">{record.duration}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ResponsiveUserTable;
