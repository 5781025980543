import React, { useState, useEffect } from "react";
import { Login } from "../../../API";
import { useNavigate, Link } from "react-router-dom";
import { Form, Input, Button, Checkbox, Row, Col, Divider, Image } from "antd";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  UserOutlined,
  LockOutlined
} from "@ant-design/icons";
import { message } from "antd";

import { useAuth } from "../../../context/AuthContext";

const Signin = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    const storedUser = localStorage.getItem("reminder");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      form.setFieldsValue(parsedUser);
      setRememberMe(true);
    }
  }, [form]);
  const onFinish = async (values) => {
    try {
      const data = await Login(values);
      if (data.status === 200) {
        message.success("Login successful");
        navigate("/");
      } else if (data.status === 401) {
        message.error(
          "Invalid credentials. Please check your employeeId and password."
        );
      }

      if (rememberMe) {
        localStorage.setItem("reminder", JSON.stringify(values));
      }
    } catch (error) {
      message.error(
        "Invalid credentials. Please check your employeeId and password."
      );
    }
  };
  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-100 to-white flex justify-center items-center">
      <div className="flex justify-center items-center w-full max-w-screen-lg flex-col sm:flex-row bg-white rounded-2xl shadow-2xl m-4">
        {/* Left column with logo */}
        <div className="hidden sm:flex sm:w-1/2 items-center justify-center p-8 bg-gradient-to-br from-blue-50 to-white rounded-l-2xl">
          <img
            className="max-w-full max-h-[400px] rounded-3xl transform hover:scale-105 transition-transform duration-300"
            src="/images/MetaXols-Logo.jpg"
            alt="MetaXols Logo"
          />
        </div>
        
        {/* Right column with form */}
        <div className="w-full sm:w-1/2 flex items-center justify-center p-8">
          <div className="w-full max-w-md">
            <div className="text-center mb-8">
              <h1 className="text-3xl font-bold mb-2">
                Welcome to <span className="text-blue-600 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-blue-400">MetaXols</span>
              </h1>
              <p className="text-gray-600">Please enter your credentials to continue</p>
            </div>

            <Form
              form={form}
              name="normal_login"
              onFinish={(values) => onFinish(values, form)}
              layout="vertical"
              className="space-y-4"
            >
              <Form.Item
                name="email"
                label={<span className="text-gray-700 font-medium">Employee Email</span>}
                rules={[{ required: true, message: 'Please enter your email!' }]}
              >
                <Input 
                  prefix={<UserOutlined className="text-gray-400" />}
                  placeholder="Enter your email"
                  className="h-11 rounded-lg"
                />
              </Form.Item>

              <Form.Item
                name="password"
                label={<span className="text-gray-700 font-medium">Password</span>}
                rules={[{ required: true, message: 'Please input your Password!' }]}
              >
                <Input.Password
                  prefix={<LockOutlined className="text-gray-400" />}
                  placeholder="Enter your password"
                  className="h-11 rounded-lg"
                  iconRender={(visible) =>
                    visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>

              <Form.Item>
                <div className="flex items-center justify-between">
                  <Checkbox 
                    checked={rememberMe} 
                    onChange={(e) => setRememberMe(e.target.checked)}
                    className="text-gray-600"
                  >
                    Remember me
                  </Checkbox>
                  {/* <Link to="/forgot-password" className="text-blue-600 hover:text-blue-700">
                    Forgot password?
                  </Link> */}
                </div>
              </Form.Item>

              <Form.Item>
                <Button 
                  type="primary" 
                  htmlType="submit" 
                  block
                  className="h-11 text-lg font-medium rounded-lg bg-gradient-to-r from-blue-600 to-blue-400 hover:from-blue-700 hover:to-blue-500 border-0 shadow-lg hover:shadow-xl transition-all duration-300"
                >
                  Sign In
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
