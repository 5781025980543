import React, { useEffect, useState } from "react";
import { getEmployee } from "../../API";

const EmployeeCard = ({ title, content }) => {
  const [employee, setEmployee] = useState();
  const handleCheckInOut = () => {

  };
 useEffect(() => {
  const fetchData = async () => {
    try {
      const employees = await getEmployee();
      const numberOfEmployees = employees?.data?.total;
      setEmployee(numberOfEmployees);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };
  fetchData()
 })

  return (
    <div className="transform hover:scale-105 transition-transform duration-300">
      <div className="bg-gradient-to-r from-blue-500 to-blue-600 rounded-xl shadow-xl p-6">
        <div className="space-y-4">
          <h3 className="text-xl md:text-2xl font-bold text-white tracking-wide">
            {title}
          </h3>
          <div className="space-y-2">
            <p className="text-blue-100 font-medium text-sm md:text-base">
              Number of Employees
            </p>
            <div className="flex items-center justify-center">
              <span className="text-3xl md:text-4xl font-bold text-white">
                {employee || 0}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeCard;
