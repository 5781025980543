import { Form, Input, Button, Tag, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { ResetPassword } from '../../../API';
import { FaKey, FaUserAlt, FaCopy } from 'react-icons/fa';
import { RiLockPasswordLine } from 'react-icons/ri';

const EmployeePassword = ({state}) => {
  const [form] = Form.useForm();
  const { id } = useParams(); 
  const [copied, setCopied] = useState(false);

  const history = useNavigate();
  const onFinish = async (values) => {
    try {
      const employeeIdValue = form.getFieldValue("employeeId");
     const response= await ResetPassword(employeeIdValue);
      if (response.data.success) {
        message.success(response.data.message);
        showMessage(response.data.password);
        history('/employee');
      }else {
      message.error('Error resetting password');
        
      }
    } catch (error) {
    }
  };

  const showMessage = (text) => {
    message.success(
      <div className="space-y-2">
        <h4 className="text-lg font-semibold text-gray-800">Employee Details</h4>
        <div className="flex items-center space-x-2">
          <span className="text-gray-600">Employee Password:</span>
          <Tag color="orange" className="px-3 py-1">{text}</Tag>
          <button
            onClick={() => copyToClipboard(text)}
            className="flex items-center gap-2 px-3 py-1 text-sm bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
          >
            <FaCopy /> Copy
          </button>
        </div>
      </div>,
      20
    );
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    message.success('Password copied to clipboard', 2);
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full mx-auto space-y-8 bg-white p-8 rounded-xl shadow-lg">
        <div className="text-center">
          <FaKey className="mx-auto h-12 w-12 text-blue-500" />
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
            Reset Employee Password
          </h2>
        </div>

        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          className="mt-8 space-y-6"
        >
          <Form.Item
            label={
              <span className="flex items-center gap-2 text-gray-700 font-medium">
                <FaUserAlt className="text-blue-500" />
                Employee ID
              </span>
            }
            name="employeeId"
            rules={[{ required: true, message: 'Please enter the Employee ID!' }]}
          >
            <Input
              placeholder="Enter Employee ID"
              className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
            />
          </Form.Item>

          <Form.Item>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <RiLockPasswordLine className="h-5 w-5 text-blue-500 group-hover:text-blue-400" />
              </span>
              Reset Password
            </button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default EmployeePassword;
