// Header.js

import { io } from "socket.io-client";
import { useEffect, useRef, useState } from 'react';
import { Layout, Dropdown, Menu, Button, Avatar, Image, Space, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './index.scss'; // Import the custom styles
import { useNavigate } from 'react-router-dom'
import notificationSound from '../../audio.mp3'; // Import sound file

import { Modal } from 'antd';
import { BellFilled } from '@ant-design/icons';

import { getSingleEmployee } from '../../API';
import axios from 'axios';
const { Header } = Layout;
const AppHeader = () => {
  const [name, setName] = useState('');
  const [postion, setPostion] = useState('');
  const id = localStorage?.getItem('user');
  const cleanedId = id?.replace(/"/g, '');
  const [isAdmin, setIsAdmin] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const audioRef = useRef(new Audio(notificationSound));
  const [previousUnreadCount, setPreviousUnreadCount] = useState(0);
  const [previouslySeenIds, setPreviouslySeenIds] = useState(() => {
    const saved = localStorage.getItem('seenNotifications');
    return saved ? JSON.parse(saved) : [];
  });
  const isNewNotification = (notification) => {
    return !previouslySeenIds.includes(notification._id) &&
      !notification.readBy.includes(localStorage.getItem('user')?.replace(/"/g, ''));
  };
  const ADMIN_ID = "660fc9393e4342fac01665dc";
  const [showAddNotification, setShowAddNotification] = useState(false);
  const [newNotification, setNewNotification] = useState({ title: '', message: '' });
  const handleNotificationClick = (notification) => {
    setPreviouslySeenIds(prev => {
      const updated = [...prev, notification._id];
      localStorage.setItem('seenNotifications', JSON.stringify(updated));
      return updated;
    });
    setSelectedNotification(notification);
    setIsModalVisible(true);
    markAsRead(notification._id);
    setShowNotifications(false);
  };

  const socketRef = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const payload = JSON.parse(atob(token.split('.')[1]));
      setIsAdmin(payload.isAdmin);
    }
    
    socketRef.current = io('https://api.metaxols.com');

    // Updated socket event handler
    socketRef.current.on('newNotification', (notification) => {
      setNotifications(prev => [notification, ...prev]);
      const cleanUserId = localStorage.getItem('user')?.replace(/"/g, '');
      
      if (!isAdmin && cleanUserId !== ADMIN_ID) {
        // Increment unread count
        setUnreadCount(prev => prev + 1);
        // Play sound and show message
        audioRef.current.play().catch(console.error);
        message.info({
          content: 'New notification received',
          duration: 3,
          icon: <BellFilled style={{ color: '#1890ff' }} />
        });
      }
    });

    socketRef.current.on('notificationUpdate', (updatedNotification) => {
      setNotifications(prev => prev.map(notification =>
        notification._id === updatedNotification._id ? updatedNotification : notification
      ));
    });

    fetchNotifications();

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  const fetchNotifications = async () => {
    try {
      const response = await axios.get('https://api.metaxols.com/notifications');
      setNotifications(response.data.data);
      const cleanUserId = localStorage.getItem('user')?.replace(/"/g, '');
      const unread = response.data.data.filter(n =>
        !n.readBy.some(id => id.toString() === cleanUserId)
      ).length;
      if (!isAdmin && cleanUserId !== ADMIN_ID) {
        if (unread > previousUnreadCount) {
          // Show Ant Design message
          message.info({
            content: `You have ${unread} new notification${unread > 1 ? 's' : ''}`,
            duration: 3, // Show for 3 seconds
            icon: <BellFilled style={{ color: '#1890ff' }} />
          });
        }
      }
      setUnreadCount(unread);
      // Show Ant Design message


    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };
  // New useEffect to handle notification sound
  useEffect(() => {
    // Only play sound if:
    // 1. User is not an admin
    // 2. There's a new unread notification
    // 3. Current unread count is greater than previous unread count
    if (!isAdmin && unreadCount > previousUnreadCount) {
      audioRef.current.play().catch(error => {
        console.error('Error playing notification sound:', error);
      });
    }

    // Update previous unread count
    setPreviousUnreadCount(unreadCount);
  }, [unreadCount, isAdmin]);

  const handleAddNotification = async () => {
    try {
      await axios.post('https://api.metaxols.com/notifications', newNotification);
      setShowAddNotification(false);
      setNewNotification({ title: '', message: '' });
      fetchNotifications();
    } catch (error) {
      console.error('Error adding notification:', error);
    }
  };


  const markAsRead = async (notificationId) => {
    try {
      const cleanUserId = localStorage.getItem('user')?.replace(/"/g, '');
      await axios.put(`https://api.metaxols.com/notifications/${notificationId}/read`, {
        employeeId: cleanUserId
      });

      // Update local state immediately
      setNotifications(prev => prev.map(notification => {
        if (notification._id === notificationId) {
          return {
            ...notification,
            readBy: [...notification.readBy, cleanUserId]
          };
        }
        return notification;
      }));

      // Update unread count immediately
      setUnreadCount(prev => Math.max(0, prev - 1));
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };
  const fetchData = async () => {
    const data = await getSingleEmployee(cleanedId);
    setName(data?.data?.data?.name)
    setPostion(data?.data?.data?.position)
  }
  useEffect(() => {

    fetchData();
  })
  const navigate = useNavigate();



  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('buttonText');
    localStorage.removeItem('user');
    localStorage.removeItem('checkInTime');
    localStorage.removeItem('checkOutTime');
    navigate('auth/signin');
  };


  return (
    <Header className="ant-header flex justify-between items-center px-4 sm:px-8 relative">
      <div className="logo hidden sm:block">
        <img src='images/MetaXols-Logo-Final-e1699461361606.png' alt='logo' className="logo-img" />
      </div>


      <div className="relative ml-auto mr-4">
        <Button
          type="danger"
          onClick={handleLogout}
          className="rounded-lg sm:rounded-full"
          style={{
            backgroundColor: '#ff4d4f',
            borderColor: '#ff4d4f',
            color: 'white',
            fontSize: '14px' // Adjust font size for small screens
          }}
        >
          Logout
        </Button>
        {isAdmin ? (
          <button
            onClick={() => setShowAddNotification(true)}
            className="bg-gradient-to-r ml-2 from-blue-500 to-indigo-600 text-white px-6 py-2.5 rounded-lg hover:from-blue-600 hover:to-indigo-700 transition-all duration-300 shadow-lg hover:shadow-xl font-semibold"
          >
            Add Notification
          </button>
        ) : (
          <div className="relative inline-block">
            <button
              onClick={() => setShowNotifications(!showNotifications)}
              className="p-2 ml-2 rounded-full hover:bg-gray-100 transition-all duration-300"
            >
              <BellFilled className="text-xl text-gray-600 hover:text-blue-500" />
              {unreadCount > 0 && (
                <span className="absolute -top-1 -right-1 bg-gradient-to-r from-red-500 to-pink-500 text-white text-xs w-5 h-5 flex items-center justify-center rounded-full animate-pulse">
                  {unreadCount}
                </span>
              )}
            </button>
            {showNotifications && (
              <div className="absolute right-0 mt-3 w-full max-w-md sm:w-96 bg-white rounded-xl shadow-xl border border-gray-100 z-50 overflow-hidden transform transition-all duration-300 ease-in-out">
                <div className="bg-gradient-to-r from-blue-600 to-indigo-700 px-4 py-3 rounded-t-xl">
                  <h3 className="text-white font-semibold text-lg">Notifications</h3>
                </div>
                <div className="max-h-[400px] overflow-y-auto custom-scrollbar p-2">
                  {notifications.length === 0 ? (
                    <div className="p-6 text-center text-gray-500 flex flex-col items-center">
                      <BellFilled className="text-4xl mb-2 text-gray-400" />
                      <p>No notifications yet</p>
                    </div>
                  ) : (
                    notifications.map(notification => (
                      <div
                        key={notification._id}
                        onClick={() => handleNotificationClick(notification)}
                        className={`
              relative p-4 border-b border-gray-200 cursor-pointer transition-all duration-300 ease-in-out 
              hover:bg-gradient-to-r hover:from-blue-50 hover:to-indigo-50 rounded-lg 
              ${!notification.readBy.includes(localStorage.getItem('user'))
                            ? 'bg-gradient-to-r from-blue-50 to-indigo-50 border-l-4 border-l-blue-500'
                            : 'hover:border-l-4 hover:border-l-gray-300'}
            `}
                      >
                        <div className="flex items-center space-x-2">
                          <BellFilled className="text-xl text-blue-500" />
                          <h3 className="font-semibold text-gray-800 mb-1">{notification.title}</h3>
                          {isNewNotification(notification) && (
                            <span className="absolute top-4 right-4 h-3 w-3 bg-red-500 rounded-full animate-pulse">
                              <span className="absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75 animate-ping"></span>
                            </span>
                          )}
                        </div>
                        <p className="text-sm text-gray-600 line-clamp-2">{notification.message}</p>
                        <span className="text-xs text-gray-400 mt-2 inline-block">
                          {new Date(notification.createdAt).toLocaleString()}
                        </span>
                      </div>
                    ))
                  )}
                </div>
              </div>
            )}

          </div>
        )}
      </div>
      <Modal
        title={
          <div className="bg-gradient-to-r from-blue-500 to-blue-900 -mx-6 -mt-4 px-6 py-4 rounded-t-lg">
            <h3 className="text-white text-xl font-semibold">
              {selectedNotification?.title}
            </h3>
          </div>
        }
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={
          <div className="flex justify-end">
            <Button onClick={() => setIsModalVisible(false)}>
              Close
            </Button>
          </div>
        }
        className="notification-detail-modal rounded-lg"
        closable={false} // Disable the default close icon
      >
        <div className="p-6 space-y-4">
          <p className="text-gray-700 text-base mb-6 leading-relaxed">
            {selectedNotification?.message}
          </p>
          <div className="text-gray-500 text-sm flex items-center space-x-2">
            <span className="mr-2 text-lg">📅</span>
            <span>
              {new Date(selectedNotification?.createdAt).toLocaleString()}
            </span>
          </div>
        </div>
      </Modal>

      <Modal
        title={
          <div className="bg-gradient-to-r from-blue-600 to-indigo-700 -mx-6 -mt-4 px-6 py-4 rounded-t-lg shadow-lg">
            <h3 className="text-white text-xl font-semibold">Add New Notification</h3>
          </div>
        }
        open={showAddNotification}
        onCancel={() => setShowAddNotification(false)}
        footer={null}
        className="rounded-lg shadow-xl"
      >
        <div className="p-6 space-y-4">
          <input
            type="text"
            placeholder="Notification Title"
            className="w-full mb-4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300 shadow-md hover:shadow-lg"
            value={newNotification.title}
            onChange={(e) => setNewNotification({ ...newNotification, title: e.target.value })}
          />
          <textarea
            placeholder="Notification Message"
            className="w-full mb-6 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300 shadow-md hover:shadow-lg"
            rows={4}
            value={newNotification.message}
            onChange={(e) => setNewNotification({ ...newNotification, message: e.target.value })}
          />
          <div className="flex justify-end space-x-4">
            <button
              onClick={() => setShowAddNotification(false)}
              className="px-6 py-2.5 text-gray-600 hover:bg-gray-100 rounded-lg transition-all duration-300 shadow-md hover:shadow-lg"
            >
              Cancel
            </button>
            <button
              onClick={handleAddNotification}
              className="px-6 py-2.5 bg-gradient-to-r from-blue-600 to-indigo-700 text-white rounded-lg hover:from-blue-700 hover:to-indigo-800 transition-all duration-300 shadow-lg hover:shadow-xl"
            >
              Add Notification
            </button>
          </div>
        </div>
      </Modal>


    </Header>
  );
};

export default AppHeader;
