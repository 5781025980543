// Header.js

import React from 'react';
import { Row,Col,Image,Dropdown} from 'antd';
import { useNavigate } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import './index.scss'; // Import the custom styles
import EmployeeDetails from '../../../Pages/Employee/EmployeeDetails';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Space } from 'antd';

const PackageCard = ({image,name,id,position,address,number,active,employee}) => {
  const navigate = useNavigate(); 
  const handleSubmit = (values) => {}

  const handleArrowClick = () => {
    navigate(`/employeedetails/${id}`);
  };
  return (
    <div className="packageCard p-6 rounded-lg shadow-lg bg-blue-100 hover:shadow-2xl transition-shadow duration-300">
      <Row gutter={16}>
        <Col span={24}>
          <div className="cardContainer flex flex-col md:flex-row items-center">
            <div className="imageSection flex justify-center mb-4 md:mb-0 md:mr-6">
              <Avatar size={64} icon={<UserOutlined />} className="bg-blue-500" />
            </div>
            <div className="pkgDetail flex-1">
              <div className="packageInfo mb-4">
                <h1 className="text-2xl font-semibold">{name}</h1>
              </div>
              <div className="packageEssentials grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="info">
                  <p className="heading font-semibold text-blue-500">Position</p>
                  <p className="detail text-gray-700">{position}</p>
                </div>
                <div className="info">
                  <p className="heading font-semibold text-blue-500">User ID</p>
                  <p className="detail text-gray-700">{id}</p>
                </div>
                <div className="info">
                  <p className="heading font-semibold text-blue-500">Address</p>
                  <p className="detail text-gray-700">{address}</p>
                </div>
                <div className="info">
                  <p className="heading font-semibold text-blue-500">Contact Number</p>
                  <p className="detail text-gray-700">{number}</p>
                </div>
              </div>
            </div>
            <div className="linkArrow ml-auto cursor-pointer" onClick={handleArrowClick}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M14.4302 5.93018L20.5002 12.0002L14.4302 18.0702" stroke="#1E40AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3.5 12H20.33" stroke="#1E40AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PackageCard;
