import React, { useState, useEffect } from 'react';
import { Card, Button, message, Empty, Spin } from 'antd';
import { FileTextOutlined, DeleteOutlined, EyeOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import { getPolicies, archivePolicy } from '../../API/index';
import './styles.css';
import './../../Components/common/headerStyles.css';

const { Meta } = Card;

const CompanyPolicy = () => {
  const [policies, setPolicies] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPolicies();
  }, []);

  const token = localStorage.getItem("token");
  let isAdmin = false;
  if (token) {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      isAdmin = payload.isAdmin;
      console.log(isAdmin);
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  }

  const fetchPolicies = async () => {
    try {
      const response = await getPolicies();
      // Filter out archived policies before setting the state
      const filteredPolicies = response.data.filter((policy) => !policy.isArchived);
      setPolicies(filteredPolicies);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching policies:', error);
      message.error('Failed to fetch policies');
    }
  };

  const handleCardClick = (fileUrl) => {
    window.open(fileUrl, '_blank');
  };

  const handleArchive = async (policyId) => {
    try {
      const response = await archivePolicy(policyId);
      console.log(response);
      if (response.success) {
        message.success('Policy archived successfully');
        fetchPolicies(); // Refresh policies after archiving
      } else {
        message.error('Failed to archive policy');
      }
    } catch (error) {
      console.error('Error archiving policy:', error);
      message.error('Failed to archive policy');
    }
  };

  return (
    <div className="policy-container">
      <div className="page-header">
        <h1 className="page-title">
          <SafetyCertificateOutlined /> Company Policies
        </h1>
        <p className="page-subtitle">Access and manage all company policies in one place</p>
      </div>

      <div className="policy-grid">
        {loading ? (
          <div className="loading-container">
            <Spin size="large" />
          </div>
        ) : policies.length > 0 ? (
          policies.map((policy) => (
            <Card
              key={policy._id}
              hoverable
              className="policy-card"
              cover={
                <div className="card-cover">
                  <FileTextOutlined className="file-icon" />
                  <div className="policy-overlay">
                    <EyeOutlined className="view-icon" />
                  </div>
                </div>
              }
              onClick={(e) => {
                // Prevent click if clicking archive button
                if (e.target.closest('.archive-btn')) return;
                handleCardClick(policy.fileUrl);
              }}
            >
              <Meta
                title={<div className="policy-card-title">{policy.title}</div>}
                description={
                  <div className="card-actions">
                    <button className="view-btn" onClick={() => handleCardClick(policy.fileUrl)}>
                      View Policy
                    </button>
                    {isAdmin && (
                      <button 
                        className="archive-btn"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleArchive(policy._id);
                        }}
                      >
                        <DeleteOutlined /> Archive
                      </button>
                    )}
                  </div>
                }
              />
            </Card>
          ))
        ) : (
          <div className="empty-state">
            <Empty
              description="No policies found"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyPolicy;
