import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import {Pagination} from 'antd';
import PageHeader from '../../Components/common/pageHeader';
import PackageCard from '../../Components/Cards/statsCard';
import axios from 'axios'
import { getEmployee } from '../../API'
import {DashboardOutlined} from '@ant-design/icons';
// import EmployeeDetail from './EmployeeDetails';
const Employee = () => {
  const [employeeData, setEmployeeData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);


  const navigate = useNavigate();
  const fetchData = async (search = '', page = 1, pageSize = 10) => {
    try {
      const response = await getEmployee(search, page, pageSize, false);
      setEmployeeData(response?.data?.data);
      setTotalRecords(response?.data?.total);
    } catch (error) {
      console.error('Error fetching employee data:', error);
    }
  };
  

  useEffect(() => {
    fetchData();
  }, []);;

  const handleSearch = (value) => {
    setCurrentPage(1);
    fetchData(value.toLowerCase());
  };
  
  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchData('', page);
  };




  const navigateToEmployeeDetail = () => {
    navigate('/employeform');
  };

  return (
    <>
      <div className="Dashboard-page bg-gradient-to-r from-blue-500 to-blue-700 p-6 rounded-lg shadow-lg">
        <div className="page-header mb-6">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="text-white mb-4 md:mb-0">
              <h1 className="page-title text-2xl font-bold flex items-center gap-2">
                <DashboardOutlined /> Employee Management
              </h1>
              <p className="page-subtitle opacity-90">Add and manage your organization's employees</p>
            </div>
            <div className="flex flex-col sm:flex-row items-center gap-4 w-full md:w-auto">
              <Input.Search
                placeholder="Search employees..."
                onSearch={handleSearch}
                onChange={(e) => handleSearch(e.target.value)}
                className="w-full sm:w-64 md:w-80"
                size="large"
                style={{ borderRadius: '8px' }}
              />
              <Button
                className="w-full sm:w-auto bg-white text-blue-700 hover:bg-blue-50 
                          border-2 border-white hover:border-blue-50 shadow-md 
                          flex items-center justify-center gap-2"
                onClick={navigateToEmployeeDetail}
                size="large"
              >
                <span role="img" aria-label="add-user">👤</span>
                Add Employee
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8 p-6 bg-white rounded-lg shadow-md">
        <Row gutter={[16, 16]}>
          {employeeData.map((employee, index) => (
            <Col span={24} md={12} lg={8} key={index}>
              <PackageCard
                name={employee?.name}
                id={employee?.employeeId}
                position={employee?.position}
                address={employee?.currentAddress?.city}
                number={employee?.phone}
                active={employee?.active}
                employee={setEmployeeData}
              />
            </Col>
          ))}
        </Row>
        <Pagination
          current={currentPage}
          total={totalRecords}
          pageSize={10}
          onChange={handlePageChange}
          className="mt-6 flex justify-center"
          showSizeChanger={false}
        />
      </div>
    </>
  );
};

export default Employee;